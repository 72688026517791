import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import './scss/style.scss';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
//IF WE USE HASHROUTER THEN WILL GET # IN THE URL EX.3000/#/DASHBORAD
import { useCookies } from 'react-cookie';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
//LAZY LOAD IMPROVES THE REACT BASED APPLICATION PERFORMANCE
//LAZY LOAD SPLITS THE BUNDLE.JS FILE BASED ON THE ROUTES HERE IT WILL CREATE A SEPARATE FILE FOR BOTH LOGIN AND REGISTER.
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
//Alcoats pages
const AdminLogin = React.lazy(() => import('./views/AdminViews/Logins/Adminlogin'));

//Admin layout and views
const AppConstants = React.lazy(() => import('./views/AdminViews/AppConstants'));

const TheLayoutAdmin = React.lazy(() => import('./containerAdmin/TheLayoutAdmin'));
const AdminDashboard = React.lazy(() => import('./views/AdminViews/AdminDashboard'));

const AddNewUser = React.lazy(() => import('./views/AdminViews/User/UserAdd'));
const UserUpdate = React.lazy(() => import('./views/AdminViews/User/UserAdd/UserUpdate'));
const UserList = React.lazy(() => import('./views/AdminViews/User/UserLIst'));
const UserDetails = React.lazy(() => import('./views/AdminViews/User/UserDetails'));
const UserActions = React.lazy(() => import('./views/AdminViews/User/UserLIst/UserActionLIst'));
const RequestCredit = React.lazy(() => import('./views/AdminViews/User/EmployeeReimbursement/RequestCredit'));
const UploadExpenseBills = React.lazy(() => import('./views/AdminViews/User/EmployeeReimbursement/UploadExpenseBills'));
const ExpenseList = React.lazy(() => import('./views/AdminViews/User/EmployeeReimbursement/ExpenseList'));

const EmpAttendenceAdd = React.lazy(() => import('./views/AdminViews/HumanResource/EmployeeAttendence/EmployeeAttendenceAdd'));
const EmpAttendenceAddSingle = React.lazy(() => import('./views/AdminViews/HumanResource/EmployeeAttendence/EmployeeAttendenceAddSingle'));
const EmpAttendenceList = React.lazy(() => import('./views/AdminViews/HumanResource/EmployeeAttendence/EmployeeAttendenceList'));

const AddNewMember = React.lazy(() => import('./views/AdminViews/Member/AddNewMember'));
const MemberList = React.lazy(() => import('./views/AdminViews/Member/MemberList'));
const MemberDetails = React.lazy(() => import('./views/AdminViews/Member/MemberDetails'));
const MemberImport = React.lazy(() => import('./views/AdminViews/Member/MembersImport'));

const BulkWhatsApp = React.lazy(() => import('./views/AdminViews/Marketing/BulkWhastApp'));


const AddNewRFQ = React.lazy(() => import('./views/AdminViews/RFQ/CreateNewRFQ'));
const RFQList = React.lazy(() => import('./views/AdminViews/RFQ/RFQList'));
const RFQImport = React.lazy(() => import('./views/AdminViews/RFQ/RFQImport'));
const RFQDetails = React.lazy(() => import('./views/AdminViews/RFQ/RFQDetails'));
const RFQUpdate = React.lazy(() => import('./views/AdminViews/RFQ/RFQUpdate'));

const SupRFQDetails = React.lazy(() => import('./views/AdminViews/RFQ/SupplierRfqDetails'));
const SupRFQUpdate = React.lazy(() => import('./views/AdminViews/RFQ/SupplierRfqUpdate'));
const CreateNewQuotation = React.lazy(() => import('./views/AdminViews/SupplierQuatation/CreateNewQuotation'));
const OnGoingProject = React.lazy(() => import('./views/AdminViews/Projects/OnGoingProject'));
const ProjectDetails = React.lazy(() => import('./views/AdminViews/Projects/ProjectsDetails'));

const AddNewTask = React.lazy(() => import('./views/AdminViews/Tasks/AddNewTask'));
const TaskList = React.lazy(() => import('./views/AdminViews/Tasks/TaskList'));
const TaskUpdate = React.lazy(() => import('./views/AdminViews/Tasks/TaskUpdate'));

const BOM = React.lazy(() => import('./views/AdminViews/BOM'));
const BomList = React.lazy(() => import('./views/AdminViews/BOM/BomList'));
const BomDetails = React.lazy(() => import('./views/AdminViews/BOM/BomDetails'));

const CustomerQuotationAdd = React.lazy(() => import('./views/AdminViews/CustomerQuotation/AddCustomerQuotation'));
const CustomerQuotationList = React.lazy(() => import('./views/AdminViews/CustomerQuotation/CustomerQuotationList'));
const CustomerQuotationDetails = React.lazy(() => import('./views/AdminViews/CustomerQuotation/CustomerQuotationDetails'));
const UpdateCustomerQuotation = React.lazy(() => import('./views/AdminViews/CustomerQuotation/UpdateCustomerQuotation'));
const CustQuoRevComparison = React.lazy(() => import('./views/AdminViews/CustomerQuotation/CustQuoRevComparison'));

const CustomerPurchaseOrderAdd = React.lazy(() => import('./views/AdminViews/CustomerPurchaseOrder/AddCustomerPurchaseOrder'));
const CustomerPurchaseOrderList = React.lazy(() => import('./views/AdminViews/CustomerPurchaseOrder/CustomerPurchaseOrderList'));
const CustomerPurchaseOrderDetails = React.lazy(() => import('./views/AdminViews/CustomerPurchaseOrder/CustomerPurchaseOrderDetails'));
const CustomerPurchaseOrderUpdate = React.lazy(() => import('./views/AdminViews/CustomerPurchaseOrder/UpdateCustomerPurchaseOrder'));

const SupplierPurchaseOrderAdd = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrder/AddSupplierPurchaseOrder'));
const SupplierPurchaseOrderList = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrder/SupplierPurchaseOrderList'));
const SupplierPurchaseOrderDetails = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrder/SupplierPurchaseOrderDetails'));
const SupplierPurchaseOrderUpdate = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrder/UpdateSupplierPurchaseOrder'));

// const CustomerPurchaseOrderDetails=React.lazy(()=>import('./views/AdminViews/CustomerPurchaseOrder/CustomerPurchaseOrderDetails'));
// const CustomerPurchaseOrderUpdate=React.lazy(()=>import('./views/AdminViews/CustomerPurchaseOrder/UpdateCustomerPurchaseOrder'));




const PurchaseIndentAdd = React.lazy(() => import('./views/AdminViews/CustomerPurchaseIndents/PurchaseIndents'));
const PurchaseIndentSList = React.lazy(() => import('./views/AdminViews/CustomerPurchaseIndents/PurchaseIndentList'));
const PurchaseIndentDetails = React.lazy(() => import('./views/AdminViews/CustomerPurchaseIndents/PurchaseIndentDetails'));
const PurchaseIndentListByStatusText = React.lazy(() => import('./views/AdminViews/CustomerPurchaseIndents/PurchaseIndentListByStatusText'));

//const CustomerPurchaseOrderUpdate=React.lazy(()=>import('./views/AdminViews/CustomerPurchaseOrder/UpdateCustomerPurchaseOrder'));


const SupplierQuatationList = React.lazy(() => import('./views/AdminViews/SupplierQuatation/SupplierQuatationList'));
const SupplierQuatationPrint = React.lazy(() => import('./views/AdminViews/SupplierQuatation/SupplierQuatationPrint'));
const ConsolidateNow = React.lazy(() => import('./views/AdminViews/SupplierQuatation/ConsolidateNow'));

const AddNewPartnumber = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/AddNewPartnumber'));
const PartnumberList = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartnumberList'));
const PartnumberImport = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartnumberImport'));
const PartNumberDetails = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartnumberDetails'));
const UpdatePartNumber = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/UpdatePartNumber'));
const PartNumberPriceHistory = React.lazy(() => import('./views/AdminViews/ListPages/PartNumberPriceHistory'));
const PartNumberOccupationHistory = React.lazy(() => import('./views/AdminViews/ListPages/PartNumberOccupationHistory'));


const AddNewProcess = React.lazy(() => import('./views/AdminViews/Process/CreateNewProcess'));
const ProcessList = React.lazy(() => import('./views/AdminViews/Process/ProcessList'));
const ProcessImport = React.lazy(() => import('./views/AdminViews/Process/ProcessImport'));
const ProcessUpdate = React.lazy(() => import('./views/AdminViews/Process/ProcessUpdate'));
const ProcessDetails = React.lazy(() => import('./views/AdminViews/Process/ProcessDetails'));
const AddNewProcessCategory = React.lazy(() => import('./views/AdminViews/ProcessCategory'));

const AddNewCustomer = React.lazy(() => import('./views/AdminViews/Customer/CustomerAdd'));
const CustomerList = React.lazy(() => import('./views/AdminViews/Customer/CustomerList'));
const CustomerDetails = React.lazy(() => import('./views/AdminViews/Customer/CustomerDetails'));
const CustomerUpdate = React.lazy(() => import('./views/AdminViews/Customer/CustomerAdd/CustomerUpdate'));
const CustomerImport = React.lazy(() => import('./views/AdminViews/Customer/CustomerImport'));

const AddNewSupplier = React.lazy(() => import('./views/AdminViews/Suppliers/AddNewSupplier'));
const SupplierList = React.lazy(() => import('./views/AdminViews/Suppliers/SupplierList'));
const UpdateSupplier = React.lazy(() => import('./views/AdminViews/Suppliers/SupplierUpdate'));
const SupplierImport = React.lazy(() => import('./views/AdminViews/Suppliers/SupllierImport'));
const SupplierDetails = React.lazy(() => import('./views/AdminViews/Suppliers/SuppliersDetails'));
const SupplierActionList = React.lazy(() => import('./views/AdminViews/Suppliers/SupActionLIst'));

// const AddNewPurchaseIndent = React.lazy(() => import('./views/AdminViews/PurchaseIndent/AddNewPurchaseIndent'));
// const PurchaseIndentList = React.lazy(() => import('./views/AdminViews/PurchaseIndent/PurchaseIndentList'));

const BasiclSetting = React.lazy(() => import('./views/AdminViews/Setting/BasicSettings'));
const GeneralSetting = React.lazy(() => import('./views/AdminViews/Setting/GeneralSetting'));
const GeneralSettingUpdate = React.lazy(() => import('./views/AdminViews/Setting/GeneralSettingUpdate'));
const PrefixSetting = React.lazy(() => import('./views/AdminViews/Setting/PrefixSetting'));
const AddNewMailConfiguration = React.lazy(() => import('./views/AdminViews/Setting/MailConfiguration/AddNewMailConfiguration'));
const MailConfigurationList = React.lazy(() => import('./views/AdminViews/Setting/MailConfiguration/MailConfigurationList'));
const TermsConditionSetup = React.lazy(() => import('./views/AdminViews/Setting/TermsandConditions'));
const States = React.lazy(() => import('./views/AdminViews/Setting/States'));
const OtherTotalSetting = React.lazy(() => import('./views/AdminViews/Setting/OtherTotalSetting'));
const Countries = React.lazy(() => import('./views/AdminViews/Setting/Countries'));
const CompanyDetails = React.lazy(() => import('./views/AdminViews/Setting/CompanyDetails'));
const CompanyBankDetailsAdd = React.lazy(() => import('./views/AdminViews/Setting/BankDetails/BankDetailsAdd'));
const CompanyBankDetailsList = React.lazy(() => import('./views/AdminViews/Setting/BankDetails/BankDetailsList'));
const CompanyBankDetails = React.lazy(() => import('./views/AdminViews/Setting/BankDetails/BankDetails'));
const CurrencySetup = React.lazy(() => import('./views/AdminViews/Setting/CurrencySetup'));
const PaymantTypeSetup = React.lazy(() => import('./views/AdminViews/Setting/PaymantTypeSetup'));
const Department = React.lazy(() => import('./views/AdminViews/Setting/Department'));
const CurrencyConversion = React.lazy(() => import('./views/AdminViews/Setting/CurrencyConversion'));
const SettingPaymentTerms = React.lazy(() => import('./views/AdminViews/Setting/SettingPaymentTerms'));
const SettingDeliveryTerms = React.lazy(() => import('./views/AdminViews/Setting/SettingDeliveryTerms'));


const AddNewBranch = React.lazy(() => import('./views/AdminViews/Branch/AddNewBranch'));
const BranchList = React.lazy(() => import('./views/AdminViews/Branch/BranchList'));
const BranchDetails = React.lazy(() => import('./views/AdminViews/Branch/BranchDetails'));
const BranchUpdate = React.lazy(() => import('./views/AdminViews/Branch/BranchUpdate'));

const AddNewItem = React.lazy(() => import('./views/AdminViews/Items/CreateNewItem'));
const ItemList = React.lazy(() => import('./views/AdminViews/Items/ItemList'));
const Tax = React.lazy(() => import('./views/AdminViews/Items/TaxSetup'));
const TaxGroupSetup = React.lazy(() => import('./views/AdminViews/Items/TaxGroupSetup'));
const TaxGroupSetupDetails = React.lazy(() => import('./views/AdminViews/Items/TaxGroupSetupDetails'));
const Categories = React.lazy(() => import('./views/AdminViews/Items/Categories'));
const UnitsOfMeasurement = React.lazy(() => import('./views/AdminViews/Items/UnitOfMeasureMent'));

const AddNewStackIward = React.lazy(() => import('./views/AdminViews/StackInward/AddNewStackInward'));
const StackIwardList = React.lazy(() => import('./views/AdminViews/StackInward/StackInwardList'));
const StackIwardRegister = React.lazy(() => import('./views/AdminViews/StackInward/StackInwardRegister'));
const StackItemUpdate = React.lazy(() => import('./views/AdminViews/StackInward/StackItemUpdate'));
const ItemStack = React.lazy(() => import('./views/AdminViews/StackInward/ItemStack'));

const FileTypeSetup = React.lazy(() => import('./views/AdminViews/Setting/FileTypeSetup/FileTypeSetup'));

const PartNumberCategory = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartNumSetup/PartNumCategory/PartNumCategory'));

const PartNumberNoteSetup = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartNumSetup/NoteSetup'));

const RawMaterialSetup = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartNumSetup/RawMaterialSetup'));

const TechnologySetup = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartNumSetup/TechnologySetup/TechnologySetup'));

const MaterialSetup = React.lazy(() => import('./views/AdminViews/CustomerPartNumber/PartNumSetup/MaterialTypeSetup/MaterialTypeSetup'));

const ProdPartNumProAReqList = React.lazy(() => import('./views/AdminViews/ListPages/ProdPartNumProAReqList'));
const CustPurcOrdList = React.lazy(() => import('./views/AdminViews/ListPages/CustPurcOrdList'));
const CustQuotationList = React.lazy(() => import('./views/AdminViews/ListPages/CustQuotationList'));
const RFQListByStatusText = React.lazy(() => import('./views/AdminViews/ListPages/RFQList'));
const SupplierRFQList = React.lazy(() => import('./views/AdminViews/ListPages/SupplierRFQList'));
const TaskListByStatusText = React.lazy(() => import('./views/AdminViews/ListPages/TaskList'));
const TaskDetails = React.lazy(() => import('./views/AdminViews/ListPages/TaskDetails'));
const DocApprovalPendingList = React.lazy(() => import('./views/AdminViews/ListPages/DocApprovalPendingList'));
const ProjectListByStatusText = React.lazy(() => import('./views/AdminViews/ListPages/ProjectListByStatusText'));
const CustOpenOrders = React.lazy(() => import('./views/AdminViews/ListPages/CustOpenOrders'));
const CustOpenOrdersDeatils = React.lazy(() => import('./views/AdminViews/ListPages/CustOpenOrdersDetails'));
const SupOpenOrders = React.lazy(() => import('./views/AdminViews/ListPages/SupOpenOrders'));
const SupOpenOrdersDeatils = React.lazy(() => import('./views/AdminViews/ListPages/SupOpenOrdersDetails'));
const CustUpcomingDelivery = React.lazy(() => import('./views/AdminViews/ListPages/CustUpcomingDelivery'));
const SupUpcomingDelivery = React.lazy(() => import('./views/AdminViews/ListPages/SupUpcomingDelivery'));

const ApprovalAuthorizationUserAdd = React.lazy(() => import('./views/AdminViews/ApprovalAuthorizationSettings/ApprovalAuthorizationUserAdd'));
const ApprovalAuthorization = React.lazy(() => import('./views/AdminViews/ApprovalAuthorizationSettings/ApprovalAuthorization'));

const SupRFQList = React.lazy(() => import('./views/AdminViews/SupplierRFQList'));

const SupplierPurchaseOrderListByStatus = React.lazy(() => import('./views/AdminViews/SupplierPurchaseOrder/SupplierPOListByStatus'));

const RFQCostingAdd = React.lazy(() => import('./views/AdminViews/RFQCosting/RFQCostingAdd'));
const RFQCostingList = React.lazy(() => import('./views/AdminViews/RFQCosting/RFQCostingList'));
const RFQCostingDetails = React.lazy(() => import('./views/AdminViews/RFQCosting/RFQCostingDetails'));

const RFQCostingListByRM = React.lazy(() => import('./views/AdminViews/RFQCosting/RFQCostingListByRM'));
const RFQCostingListByBOI = React.lazy(() => import('./views/AdminViews/RFQCosting/RFQCostingListByBOI'));

const AddSupplierInvoice = React.lazy(() => import('./views/AdminViews/SupplierInvoice/AddSupplierInvoice'));
const SupplierInvoiceList = React.lazy(() => import('./views/AdminViews/SupplierInvoice/SupplierInvoiceList'));
const SupplierInvoiceDetails = React.lazy(() => import('./views/AdminViews/SupplierInvoice/SupplierInvoiceDetails'));

const AddGRN = React.lazy(() => import('./views/AdminViews/GRN/AddGrn'));
const ListGRN = React.lazy(() => import('./views/AdminViews/GRN/GRNList'));
const GRNListByStatus = React.lazy(() => import('./views/AdminViews/GRN/GRNListByStatus'));
const GRNIDDetails = React.lazy(() => import('./views/AdminViews/GRN/GRNList/GRNDetails'));
const GRNEdit = React.lazy(() => import('./views/AdminViews/GRN/GRNList/GRNUpdate'));

const SupplierInvoicePaymentAdd = React.lazy(() => import('./views/AdminViews/SupplierInvoicePayment/SupplierInvoicePaymentAdd'));

const AddCutomersInvoice = React.lazy(() => import('./views/AdminViews/CustomerInvoice/AddCustomerInvoice'));
const CustInvoiceUpdate = React.lazy(() => import('./views/AdminViews/CustomerInvoice/CustomerInvoiceUpdate'));
const CustomerInvoiceList = React.lazy(() => import('./views/AdminViews/CustomerInvoice/CustomerInvoiceList'));
const CustomerInvoiceDetails = React.lazy(() => import('./views/AdminViews/CustomerInvoice/CustomerInvoiceDetails'));
const CustInvoicePaymentAdd = React.lazy(() => import('./views/AdminViews/CustInvoicePayment/CustInvoicePaymentAdd'));

const EarningsSetup = React.lazy(() => import('./views/AdminViews/HumanResource/Setup/EarningsSetup'));
const DeductionsSetup = React.lazy(() => import('./views/AdminViews/HumanResource/Setup/DeductionsSetup'));
const AddNewSalarySlip = React.lazy(() => import('./views/AdminViews/HumanResource/SalarySlip/AddNewSalarySlip'));
const SalarySlipList = React.lazy(() => import('./views/AdminViews/HumanResource/SalarySlip/SalarySlipList'));
const SalarySlipDetails = React.lazy(() => import('./views/AdminViews/HumanResource/SalarySlip/SalarySlipDetails'));
const SalarySlipUpdate = React.lazy(() => import('./views/AdminViews/HumanResource/SalarySlip/SalarySlipUpdate'));

const AddNewEmp = React.lazy(() => import('./views/AdminViews/HumanResource/Employee/EmployeeAdd'));
const EmployeeList = React.lazy(() => import('./views/AdminViews/HumanResource/Employee/EmployeeList'));
const EmployeeDetails = React.lazy(() => import('./views/AdminViews/HumanResource/Employee/EmployeeDetails'));
const EmployeeUpdate = React.lazy(() => import('./views/AdminViews/HumanResource/Employee/EmployeeUpdate'));
const EmployeeImprt = React.lazy(() => import('./views/AdminViews/HumanResource/Employee/ImportEmployees'));

const EmpRequestCredit = React.lazy(() => import('./views/AdminViews/HumanResource/EmployeeReimbursement/RequestCredit'));
const EmpExpenseBills = React.lazy(() => import('./views/AdminViews/HumanResource/EmployeeReimbursement/UploadExpenseBills'));
const EmpExpenseReimbursementStatement = React.lazy(() => import('./views/AdminViews/HumanResource/EmployeeReimbursement/ExpenseList'));
const StatementDetails = React.lazy(() => import('./views/AdminViews/HumanResource/EmployeeReimbursement/ExpenseDetails'));
const EmployeeBalance = React.lazy(() => import('./views/AdminViews/HumanResource/EmployeeReimbursement/EmployeeBalance'));

const ProcessFlowAdd = React.lazy(() => import('./views/AdminViews/ProcessFlow/ProcessFlowAdd'));
const ProcessFlowList = React.lazy(() => import('./views/AdminViews/ProcessFlow/ProcessFlowList'));
const ProcessFlowUpdate = React.lazy(() => import('./views/AdminViews/ProcessFlow/ProcessFlowUpdate'));
const ProcessFlowDetails = React.lazy(() => import('./views/AdminViews/ProcessFlow/ProcessFlowDetails'));

const SupplierBalancePaymentList = React.lazy(() => import('./views/AdminViews/Reports/SupplierBalancePaymentList'));
const CustomerBalancePaymentList = React.lazy(() => import('./views/AdminViews/Reports/CustomerBalancePaymentList'));
const SupplierEnquiryFollowupList = React.lazy(() => import('./views/AdminViews/Reports/SupplierEnquiryFollowupList'));
const CustomerEnquiryFollowupList = React.lazy(() => import('./views/AdminViews/Reports/CustomerEnquiryFollowupList'));

const FinishedGoodsStore = React.lazy(() => import('./views/AdminViews/Store/FinishedGoodsStore'));
const RejectedGoodsStore = React.lazy(() => import('./views/AdminViews/Store/RejectedGoodsStore'));
const FinishedGoodsStoreHistory = React.lazy(() => import('./views/AdminViews/Store/FinishedGoodsStoreHistory'));
const RejectedGoodsStoreHistory = React.lazy(() => import('./views/AdminViews/Store/RejectedGoodsStoreHistory'));


const NewDeliveryChallan = React.lazy(() => import('./views/AdminViews/DeliveryChallan/DeliveryChallanAdd'));
const DeliveryChallanDetails = React.lazy(() => import('./views/AdminViews/DeliveryChallan/DeliveryChallanDetails'));
const DeliveryChallanCreatedList = React.lazy(() => import('./views/AdminViews/DeliveryChallan/DeliveryChallanCreatedList'));
const DeliveryChallanUpdate = React.lazy(() => import('./views/AdminViews/DeliveryChallan/DeliveryChallanUpdate'));
const DeliveryChallanMasterList = React.lazy(() => import('./views/AdminViews/DeliveryChallan/DeliveryChallanMasterList'));

const CustPackingAdd = React.lazy(() => import('./views/AdminViews/Packing/CustPackingAdd'));
const CustPackingList = React.lazy(() => import('./views/AdminViews/Packing/CustPackingList'));
const CustPackingUpdate = React.lazy(() => import('./views/AdminViews/Packing/CustPackingUpdate'));
const CustPackingDetails = React.lazy(() => import('./views/AdminViews/Packing/CustPackingDetails'));
const CustPackingCreatedList = React.lazy(() => import('./views/AdminViews/Packing/CustPackingCreatedList'));


const DebitNoteAdd = React.lazy(() => import('./views/AdminViews/DebitNote/DebitNoteAdd'));
const DebitNoteList = React.lazy(() => import('./views/AdminViews/DebitNote/DebitNoteList'));
const DebitNoteDetails = React.lazy(() => import('./views/AdminViews/DebitNote/DebitNoteDetails'));
const DebitNoteUpdate = React.lazy(() => import('./views/AdminViews/DebitNote/DebitNoteUpdate'));


const CreditNoteAdd = React.lazy(() => import('./views/AdminViews/CreditNote/CreditNoteAdd'));
const CreditNoteList = React.lazy(() => import('./views/AdminViews/CreditNote/CreditNoteList'));
const CreditNoteDetails = React.lazy(() => import('./views/AdminViews/CreditNote/CreditNoteDetails'));
const CreditNoteUpdate = React.lazy(() => import('./views/AdminViews/CreditNote/CreditNoteUpdate'));

const OpenBalAdd = React.lazy(() => import('./views/AdminViews/LedgerStatement/AddOpeningBal'));
const OpenCloseBalList = React.lazy(() => import('./views/AdminViews/LedgerStatement/BalanceList'));
const LedgerStatement = React.lazy(() => import('./views/AdminViews/LedgerStatement/LedgerStatement'));

const UserRoleNameAdd = React.lazy(() => import('./views/AdminViews/User/UserRoles/RoleFeatureName/RoleFeatureNameAdd'));

const UserRoleAdd = React.lazy(() => import('./views/AdminViews/User/UserRoles/Role/RoleAdd'));
const UserRoleList = React.lazy(() => import('./views/AdminViews/User/UserRoles/Role/RoleList'));
const UserRoleDetails = React.lazy(() => import('./views/AdminViews/User/UserRoles/Role/RoleDetails'));

const CustInvImportExcel = React.lazy(() => import('./views/AdminViews/ImportExcel/CustInvImportExcel'));
const SupPOInvImportExcel = React.lazy(() => import('./views/AdminViews/ImportExcel/SupInvImportExcel'));
const CustPOImportExcel = React.lazy(() => import('./views/AdminViews/ImportExcel/CustPOImportExcel'));
const SupPoImportExcel = React.lazy(() => import('./views/AdminViews/ImportExcel/SupPOImportExcel'));

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      <Layout><Component {...props}></Component></Layout>
    )}>
    </Route>
  );
}

const App = () => {
  const [cookies, setCookies, removeCookie] = useCookies(['admin']);


  return (
    // <BrowserRouter>
    <HashRouter>
      <React.Suspense fallback={loading}>
        {/* {!cookies.user_type ? (<AdminLogin />) : ( */}
        <Switch>
          {/*Admin*/}

          <AppRoute exact path='/appconstants' name='Admin Dashboard' layout={TheLayoutAdmin} component={AppConstants} />

          <AppRoute exact path='/admindashboard' name='Admin Dashboard' layout={TheLayoutAdmin} component={AdminDashboard} />

          <AppRoute exact path='/adduser' name='Add New User' layout={TheLayoutAdmin} component={AddNewUser} />
          <AppRoute exact path='/userupdate' name='User Update' layout={TheLayoutAdmin} component={UserUpdate} />
          <AppRoute exact path='/userlist' name='User List' layout={TheLayoutAdmin} component={UserList} />
          <AppRoute exact path='/userdetails' name='User Details' layout={TheLayoutAdmin} component={UserDetails} />
          <AppRoute exact path='/user-actions' name='User Actions' layout={TheLayoutAdmin} component={UserActions} />
          <AppRoute exact path='/request-credit' name='RequestCredit' layout={TheLayoutAdmin} component={RequestCredit} />
          <AppRoute exact path='/upload-expense-bills' name='UploadExpenseBills' layout={TheLayoutAdmin} component={UploadExpenseBills} />
          <AppRoute exact path='/expense-reimbursement-list' name='ExpenseList' layout={TheLayoutAdmin} component={ExpenseList} />

          <AppRoute exact path='/mark-attendence' name='EmpAttendenceAdd' layout={TheLayoutAdmin} component={EmpAttendenceAdd} />
          <AppRoute exact path='/emp-mark-attendence' name='EmpAttendenceAddSingle' layout={TheLayoutAdmin} component={EmpAttendenceAddSingle} />
          <AppRoute exact path='/attendence-list' name='EmpAttendenceList' layout={TheLayoutAdmin} component={EmpAttendenceList} />

          <AppRoute exact path='/add-new-member' name='Add New Member' layout={TheLayoutAdmin} component={AddNewMember} />
          <AppRoute exact path='/memberlist' name='Member List' layout={TheLayoutAdmin} component={MemberList} />
          <AppRoute exact path='/memberdetails' name='Member Details' layout={TheLayoutAdmin} component={MemberDetails} />
          <AppRoute exact path='/memberimport' name='Member Import' layout={TheLayoutAdmin} component={MemberImport} />

          <AppRoute exact path='/addnewtask' name='Add New Task' layout={TheLayoutAdmin} component={AddNewTask} />
          <AppRoute exact path='/tasklist' name='Task List' layout={TheLayoutAdmin} component={TaskList} />
          <AppRoute exact path='/task-update' name='Task Update' layout={TheLayoutAdmin} component={TaskUpdate} />

          <AppRoute exact path='/bulkwhatsapp' name='Bulk WhatsApp' layout={TheLayoutAdmin} component={BulkWhatsApp} />

          {/* Adronan */}
          <AppRoute exact path='/addnewrfq' name='Add New RFQ' layout={TheLayoutAdmin} component={AddNewRFQ} />
          <AppRoute exact path='/rfqlist' name='RFQ List' layout={TheLayoutAdmin} component={RFQList} />
          <AppRoute exact path='/rfqimport' name='RFQ Import' layout={TheLayoutAdmin} component={RFQImport} />
          <AppRoute exact path='/rfqdetails' name='RFQ Details' layout={TheLayoutAdmin} component={RFQDetails} />
          <AppRoute exact path='/rfqupdate' name='RFQUpdate' layout={TheLayoutAdmin} component={RFQUpdate} />


          <AppRoute exact path='/suprfqdetails' name='Supplier RFQ Details' layout={TheLayoutAdmin} component={SupRFQDetails} />
          <AppRoute exact path='/suprfqupdate' name='Supplier RFQ Update' layout={TheLayoutAdmin} component={SupRFQUpdate} />

          <AppRoute exact path='/Suprfqlist' name='Sup RFQ List' layout={TheLayoutAdmin} component={SupRFQList} />

          <AppRoute exact path='/supplier-purchase-order-list-by-status' name='Supplier Purchase Order List By Status' layout={TheLayoutAdmin} component={SupplierPurchaseOrderListByStatus} />

          <AppRoute exact path='/customer-quotation-add' name='Customer Quotation Add' layout={TheLayoutAdmin} component={CustomerQuotationAdd} />
          <AppRoute exact path='/customer-quotation-list' name='Customer Quotation List' layout={TheLayoutAdmin} component={CustomerQuotationList} />

          <AppRoute exact path='/customer-quotation-details' name='Customer Quotation Details' layout={TheLayoutAdmin} component={CustomerQuotationDetails} />

          <AppRoute exact path='/customer-quotation-update' name='Customer Quotation Update' layout={TheLayoutAdmin} component={UpdateCustomerQuotation} />
          <AppRoute exact path='/cust-quo-rev-comparision' name='Customer Quotation Rev Comparision' layout={TheLayoutAdmin} component={CustQuoRevComparison} />

          <AppRoute exact path='/customer-purchase-order-add' name='Customer Purchase Order Add' layout={TheLayoutAdmin} component={CustomerPurchaseOrderAdd} />
          <AppRoute exact path='/customer-purchase-order-list' name='Customer Purchase Order List' layout={TheLayoutAdmin} component={CustomerPurchaseOrderList} />

          <AppRoute exact path='/customer-purchase-order-details' name='Customer Purchase Order Details' layout={TheLayoutAdmin} component={CustomerPurchaseOrderDetails} />
          <AppRoute exact path='/customer-purchase-order-update' name='Customer Purchase Order Update' layout={TheLayoutAdmin} component={CustomerPurchaseOrderUpdate} />

          <AppRoute exact path='/supplier-purchase-order-add' name='Supplier Purchase Order Add' layout={TheLayoutAdmin} component={SupplierPurchaseOrderAdd} />
          <AppRoute exact path='/supplier-purchase-order-list' name='Supplier Purchase Order List' layout={TheLayoutAdmin} component={SupplierPurchaseOrderList} />
          <AppRoute exact path='/supplier-purchase-order-details' name='Supplier Purchase Order Details' layout={TheLayoutAdmin} component={SupplierPurchaseOrderDetails} />
          <AppRoute exact path='/supplier-purchase-order-update' name='Supplier Purchase Order Update' layout={TheLayoutAdmin} component={SupplierPurchaseOrderUpdate} />

          {/* <AppRoute exact path='/customer-purchase-order-details' name='Customer Purchase Order Details' layout={TheLayoutAdmin} component={CustomerPurchaseOrderDetails} /> */}
          {/* <AppRoute exact path='/customer-purchase-order-update' name='Customer Purchase Order Update' layout={TheLayoutAdmin} component={CustomerPurchaseOrderUpdate} />  */}



          {/* <AppRoute exact path='/purchase-indents-add' name='Purchase Indent Add' layout={TheLayoutAdmin} component={PurchaseIndentAdd} />
          <AppRoute exact path='/purchase-indents-list' name='Purchase Indent List' layout={TheLayoutAdmin} component={PurchaseIndentSList} />
          <AppRoute exact path='/purchase-indents-details' name='Purchase Indent Details' layout={TheLayoutAdmin} component={PurchaseIndentDetails} />
          <AppRoute exact path='/purchase-indents-list-by-status-text' name='Purchase Indent List By Status Text' layout={TheLayoutAdmin} component={PurchaseIndentListByStatusText} /> */}
          {/* <AppRoute exact path='/customer-purchase-order-update' name='Customer Purchase Order Update' layout={TheLayoutAdmin} component={CustomerPurchaseOrderUpdate} /> */}


          <AppRoute exact path='/bom' name='BOM' layout={TheLayoutAdmin} component={BOM} />
          <AppRoute exact path='/bom-list' name='Bom List' layout={TheLayoutAdmin} component={BomList} />
          <AppRoute exact path='/bom-details' name='Bom Details' layout={TheLayoutAdmin} component={BomDetails} />


          <AppRoute exact path='/createnewquotation' name='Create New Quotation' layout={TheLayoutAdmin} component={CreateNewQuotation} />
          <AppRoute exact path='/ongoingprojects' name='On Going Project' layout={TheLayoutAdmin} component={OnGoingProject} />
          <AppRoute exact path='/projectdetails' name='Project Details' layout={TheLayoutAdmin} component={ProjectDetails} />

          <AppRoute exact path='/supplierquatationlist' name='Supplier Quatation List' layout={TheLayoutAdmin} component={SupplierQuatationList} />
          <Route exact path='/supplierquatation' name='Supplier Quatation Print' render={props => <SupplierQuatationPrint{...props} />} />
          <AppRoute exact path='/consolidatenow' name='Consolidate Now' layout={TheLayoutAdmin} component={ConsolidateNow} />

          <AppRoute exact path='/createpartnumber' name='Add New Partnumber' layout={TheLayoutAdmin} component={AddNewPartnumber} />
          <AppRoute exact path='/partnumberlist' name='Partnumber List' layout={TheLayoutAdmin} component={PartnumberList} />
          <AppRoute exact path='/importpartnumbers' name='Partnumber Import' layout={TheLayoutAdmin} component={PartnumberImport} />
          <AppRoute exact path='/partnumberdetails' name='Partnumber Details' layout={TheLayoutAdmin} component={PartNumberDetails} />
          <AppRoute exact path='/updatepartnumber' name='Partnumber Update' layout={TheLayoutAdmin} component={UpdatePartNumber} />
          <AppRoute exact path='/partnumberpricehistory' name='PartNumberPriceHistory' layout={TheLayoutAdmin} component={PartNumberPriceHistory} />
          <AppRoute exact path='/partnumber-occupation-history' name='PartNumberOccupationHistory' layout={TheLayoutAdmin} component={PartNumberOccupationHistory} />
          
          <AppRoute exact path='/createnewprocess' name='Add New Process' layout={TheLayoutAdmin} component={AddNewProcess} />
          <AppRoute exact path='/processlist' name='Process List' layout={TheLayoutAdmin} component={ProcessList} />
          <AppRoute exact path='/importprocesses' name='Process Import' layout={TheLayoutAdmin} component={ProcessImport} />
          <AppRoute exact path='/processupdate' name='Process Update' layout={TheLayoutAdmin} component={ProcessUpdate} />
          <AppRoute exact path='/processdetails' name='Process Details' layout={TheLayoutAdmin} component={ProcessDetails} />

          <AppRoute exact path='/createnewprocesscategory' name='Add New Process Category' layout={TheLayoutAdmin} component={AddNewProcessCategory} />
          
          <AppRoute exact path='/addnewcustomer' name='Add New Customer' layout={TheLayoutAdmin} component={AddNewCustomer} />
          <AppRoute exact path='/customerlist' name='Customer List' layout={TheLayoutAdmin} component={CustomerList} />
          <AppRoute exact path='/customerdetails' name='Customer Details' layout={TheLayoutAdmin} component={CustomerDetails} />
          <AppRoute exact path='/customerupdate' name='Customer Update' layout={TheLayoutAdmin} component={CustomerUpdate} />
          <AppRoute exact path='/importcustomers' name='Customer Import' layout={TheLayoutAdmin} component={CustomerImport} />

          <AppRoute exact path='/addnewsupplier' name='Add New Supplier' layout={TheLayoutAdmin} component={AddNewSupplier} />
          <AppRoute exact path='/supplierlist' name='Supplier List' layout={TheLayoutAdmin} component={SupplierList} />
          <AppRoute exact path='/supplieredit' name='Supplier Update' layout={TheLayoutAdmin} component={UpdateSupplier} />
          <AppRoute exact path='/importsupplier' name='Supplier Import' layout={TheLayoutAdmin} component={SupplierImport} />
          <AppRoute exact path='/supplierdetails' name='Supplier Details' layout={TheLayoutAdmin} component={SupplierDetails} />
          <AppRoute exact path='/sup-action-list' name='Supplier Action List' layout={TheLayoutAdmin} component={SupplierActionList} />

          <AppRoute exact path='/basicsettings' name='General Setting' layout={TheLayoutAdmin} component={BasiclSetting} />
          <AppRoute exact path='/generalsettings' name='General Setting' layout={TheLayoutAdmin} component={GeneralSetting} />
          <AppRoute exact path='/companydetailsupdate' name='General Setting' layout={TheLayoutAdmin} component={GeneralSettingUpdate} />
          <AppRoute exact path='/prefixsettings' name='Prefix Setting' layout={TheLayoutAdmin} component={PrefixSetting} />
          <AppRoute exact path='/addnewmailconfiguration' name='Add New Mail Configuration' layout={TheLayoutAdmin} component={AddNewMailConfiguration} />
          <AppRoute exact path='/mailconfiguration' name='Mail Configuration' layout={TheLayoutAdmin} component={MailConfigurationList} />
          <AppRoute exact path='/termsconditionsetup' name='Terms Condition Setup' layout={TheLayoutAdmin} component={TermsConditionSetup} />
          <AppRoute exact path='/states' name='States' layout={TheLayoutAdmin} component={States} />
          <AppRoute exact path='/othertotalsetting' name='OtherTotalSetting' layout={TheLayoutAdmin} component={OtherTotalSetting} />
          <AppRoute exact path='/currency-setup' name='CurrencySetup' layout={TheLayoutAdmin} component={CurrencySetup} />
          <AppRoute exact path='/payment-type-setup' name='PaymantTypeSetup' layout={TheLayoutAdmin} component={PaymantTypeSetup} />
          <AppRoute exact path='/department' name='Department' layout={TheLayoutAdmin} component={Department} />
          <AppRoute exact path='/currency-conversion' name='CurrencyConversion' layout={TheLayoutAdmin} component={CurrencyConversion} />
          <AppRoute exact path='/settingpaymentterms' name='SettingPaymentTerms' layout={TheLayoutAdmin} component={SettingPaymentTerms} />
          <AppRoute exact path='/settingdeliveryterms' name='SettingDeliveryTerms' layout={TheLayoutAdmin} component={SettingDeliveryTerms} />

          <AppRoute exact path='/countries' name='Countries' layout={TheLayoutAdmin} component={Countries} />
          <AppRoute exact path='/companydetails' name='Company Details' layout={TheLayoutAdmin} component={CompanyDetails} />
          <AppRoute exact path='/companybankdetailsadd' name='Company Bank Details' layout={TheLayoutAdmin} component={CompanyBankDetailsAdd} />
          <AppRoute exact path='/banklist' name='Company Bank List' layout={TheLayoutAdmin} component={CompanyBankDetailsList} />
          <AppRoute exact path='/bankdetails' name='Company Bank Details' layout={TheLayoutAdmin} component={CompanyBankDetails} />

          <AppRoute exact path='/addnewbranch' name='Add New Branch' layout={TheLayoutAdmin} component={AddNewBranch} />
          <AppRoute exact path='/branchlist' name='Branch List' layout={TheLayoutAdmin} component={BranchList} />
          <AppRoute exact path='/branchdetails' name='Branch Details' layout={TheLayoutAdmin} component={BranchDetails} />
          <AppRoute exact path='/branchupdate' name='Branch Update' layout={TheLayoutAdmin} component={BranchUpdate} />

          <AppRoute exact path='/createnewitem' name='Add New Item' layout={TheLayoutAdmin} component={AddNewItem} />
          <AppRoute exact path='/itemlist' name='Item List' layout={TheLayoutAdmin} component={ItemList} />
          <AppRoute exact path='/taxes' name='Tax Setup' layout={TheLayoutAdmin} component={Tax} />
          <AppRoute exact path='/taxgroup' name='Tax Group Setup' layout={TheLayoutAdmin} component={TaxGroupSetup} />
          <AppRoute exact path='/tax-group-details' name='Tax Group Setup Details' layout={TheLayoutAdmin} component={TaxGroupSetupDetails} />
          <AppRoute exact path='/categories' name='Categories' layout={TheLayoutAdmin} component={Categories} />
          <AppRoute exact path='/technologysetup' name='TechnologySetup' layout={TheLayoutAdmin} component={TechnologySetup} />
          <AppRoute exact path='/materilasetup' name='Material Setup' layout={TheLayoutAdmin} component={MaterialSetup} />
          <AppRoute exact path='/unitsofmeasurement' name='UnitsOfMeasurement' layout={TheLayoutAdmin} component={UnitsOfMeasurement} />

          <AppRoute exact path='/addstockinward' name='Add New Stack Inward' layout={TheLayoutAdmin} component={AddNewStackIward} />
          <AppRoute exact path='/stockinwardlist' name='Stack Inward List' layout={TheLayoutAdmin} component={StackIwardList} />
          <AppRoute exact path='/stockinwardregister' name='Stack Inward Register' layout={TheLayoutAdmin} component={StackIwardRegister} />
          <AppRoute exact path='/stockitemupdate' name='Stack Item Update' layout={TheLayoutAdmin} component={StackItemUpdate} />
          <AppRoute exact path='/itemstock' name=' Item Stack' layout={TheLayoutAdmin} component={ItemStack} />



          <AppRoute exact path='/filetypesetup' name='File Type Setup' layout={TheLayoutAdmin} component={FileTypeSetup} />

          <AppRoute exact path='/partnumcategory' name='Part Num Category' layout={TheLayoutAdmin} component={PartNumberCategory} />
          <AppRoute exact path='/partnumnotesetup' name='Part Num Note Setup' layout={TheLayoutAdmin} component={PartNumberNoteSetup} />
          <AppRoute exact path='/rawmaterialsetup' name='Raw Material Setup' layout={TheLayoutAdmin} component={RawMaterialSetup} />

          <AppRoute exact path='/prodpartnum-procapr-reqlist' name='ProdPartNumProAReqList' layout={TheLayoutAdmin} component={ProdPartNumProAReqList} />
          <AppRoute exact path='/cust-po-list' name='CustPurcOrdList' layout={TheLayoutAdmin} component={CustPurcOrdList} />
          <AppRoute exact path='/cust-quot-list' name='CustQuotationList' layout={TheLayoutAdmin} component={CustQuotationList} />
          <AppRoute exact path='/rfq-list' name='RFQListByStatusText' layout={TheLayoutAdmin} component={RFQListByStatusText} />
          <AppRoute exact path='/sup-rfq-list' name='SupplierRFQList' layout={TheLayoutAdmin} component={SupplierRFQList} />
          <AppRoute exact path='/task-list' name='TaskListByStatusText' layout={TheLayoutAdmin} component={TaskListByStatusText} />
          <AppRoute exact path='/task-details' name='TaskDetails' layout={TheLayoutAdmin} component={TaskDetails} />
          <AppRoute exact path='/doc-approval-list' name='TaskDetails' layout={TheLayoutAdmin} component={DocApprovalPendingList} />
          <AppRoute exact path='/project-list' name='ProjectListByStatusText' layout={TheLayoutAdmin} component={ProjectListByStatusText} />
          <AppRoute exact path='/cust-open-orders' name='CustOpenOrders' layout={TheLayoutAdmin} component={CustOpenOrders} />
          <AppRoute exact path='/cust-open-order-details' name='CustOpenOrdersDeatils' layout={TheLayoutAdmin} component={CustOpenOrdersDeatils} />
          <AppRoute exact path='/sup-open-orders' name='SupOpenOrders' layout={TheLayoutAdmin} component={SupOpenOrders} />
          <AppRoute exact path='/sup-open-orders-details' name='SupOpenOrdersDeatils' layout={TheLayoutAdmin} component={SupOpenOrdersDeatils} />
          <AppRoute exact path='/cust-upcoming-delivery' name='CustUpcomingDelivery' layout={TheLayoutAdmin} component={CustUpcomingDelivery} />
          <AppRoute exact path='/sup-upcoming-delivery' name='SupUpcomingDelivery' layout={TheLayoutAdmin} component={SupUpcomingDelivery} />

          <AppRoute exact path='/add-app-auth-user' name='ApprovalAuthorizationUserAdd' layout={TheLayoutAdmin} component={ApprovalAuthorizationUserAdd} />
          <AppRoute exact path='/add-app-auth' name='ApprovalAuthorization' layout={TheLayoutAdmin} component={ApprovalAuthorization} />

          <AppRoute exact path='/rfq-costing' name='RFQCostingAdd' layout={TheLayoutAdmin} component={RFQCostingAdd} />
          <AppRoute exact path='/rfq-costing-list' name='RFQ Costing List' layout={TheLayoutAdmin} component={RFQCostingList} />
          <AppRoute exact path='/rfq-cost-details' name='RFQ Costing Details' layout={TheLayoutAdmin} component={RFQCostingDetails} />

          <AppRoute exact path='/rfq-cost-list-by-boi' name='RFQCostingListByBOI' layout={TheLayoutAdmin} component={RFQCostingListByBOI} />
          <AppRoute exact path='/rfq-cost-list-by-rm' name='RFQCostingListByRM' layout={TheLayoutAdmin} component={RFQCostingListByRM} />

          <AppRoute exact path='/supplier-invoice-add' name='AddSupplierInvoice' layout={TheLayoutAdmin} component={AddSupplierInvoice} />
          <AppRoute exact path='/supplier-invoice-list' name='SupplierInvoiceList' layout={TheLayoutAdmin} component={SupplierInvoiceList} />
          <AppRoute exact path='/supplier-invoice-detail' name='SupplierInvoiceDetails' layout={TheLayoutAdmin} component={SupplierInvoiceDetails} />
          <AppRoute exact path='/sup-inv-payment' name='SupplierInvoicePaymentAdd' layout={TheLayoutAdmin} component={SupplierInvoicePaymentAdd} />


          <AppRoute exact path='/add-GRN' name='Add GRN' layout={TheLayoutAdmin} component={AddGRN} />
          <AppRoute exact path='/GRN-List' name='List GRN' layout={TheLayoutAdmin} component={ListGRN} />
          <AppRoute exact path='/GRN-List-Status' name='GRNListByStatus' layout={TheLayoutAdmin} component={GRNListByStatus} />
          <AppRoute exact path='/GRN-Details' name='GRN ID Details' layout={TheLayoutAdmin} component={GRNIDDetails} />
          <AppRoute exact path='/GRN-Edit' name='GRN Edit' layout={TheLayoutAdmin} component={GRNEdit} />

          <AppRoute exact path='/customer-invoice-add' name='AddCutomersInvoice' layout={TheLayoutAdmin} component={AddCutomersInvoice} />
          <AppRoute exact path='/customer-invoice-list' name='CustomerInvoiceList' layout={TheLayoutAdmin} component={CustomerInvoiceList} />
          <AppRoute exact path='/customer-invoice-detail' name='CustomerInvoiceDetails' layout={TheLayoutAdmin} component={CustomerInvoiceDetails} />
          <AppRoute exact path='/cust-inv-payment' name='CustInvoicePaymentAdd' layout={TheLayoutAdmin} component={CustInvoicePaymentAdd} />
          <AppRoute exact path='/customer-invoice-edit' name='CustInvoiceUpdate' layout={TheLayoutAdmin} component={CustInvoiceUpdate} />


          <AppRoute exact path='/earning-setup' name='EarningsSetup' layout={TheLayoutAdmin} component={EarningsSetup} />
          <AppRoute exact path='/deduction-setup' name='DeductionsSetup' layout={TheLayoutAdmin} component={DeductionsSetup} />
          <AppRoute exact path='/salary-slip' name='AddNewSalarySlip' layout={TheLayoutAdmin} component={AddNewSalarySlip} />
          <AppRoute exact path='/salary-slip-list' name='SalarySlipList' layout={TheLayoutAdmin} component={SalarySlipList} />
          <AppRoute exact path='/salary-slip-update' name='SalarySlipUpdate' layout={TheLayoutAdmin} component={SalarySlipUpdate} />
          {/* <AppRoute exact path='/salary-slip-details' name='SalarySlipDetails' layout={TheLayoutAdmin} component={SalarySlipDetails} />*/}

          <AppRoute exact path='/add-new-emp' name='AddNewEmp' layout={TheLayoutAdmin} component={AddNewEmp} />
          <AppRoute exact path='/emp-list' name='EmployeeList' layout={TheLayoutAdmin} component={EmployeeList} />
          <AppRoute exact path='/emp-details' name='EmployeeDetails' layout={TheLayoutAdmin} component={EmployeeDetails} />
          <AppRoute exact path='/emp-update' name='EmployeeUpdate' layout={TheLayoutAdmin} component={EmployeeUpdate} />
          <AppRoute exact path='/importemps' name='EmployeeUpdate' layout={TheLayoutAdmin} component={EmployeeImprt} />

          <AppRoute exact path='/emp-request-credit' name='EmpRequestCredit' layout={TheLayoutAdmin} component={EmpRequestCredit} />
          <AppRoute exact path='/emp-upload-expense-bills' name='EmpRequestCredit' layout={TheLayoutAdmin} component={EmpExpenseBills} />
          <AppRoute exact path='/emp-expense-reimbursement-list' name='EmpExpenseReimbursementStatement' layout={TheLayoutAdmin} component={EmpExpenseReimbursementStatement} />
          <AppRoute exact path='/emp-statemet-details' name='EmpExpenseReimbursementStatementDetails' layout={TheLayoutAdmin} component={StatementDetails} />
          <AppRoute exact path='/emp-balance' name='EmployeeBalance' layout={TheLayoutAdmin} component={EmployeeBalance} />

          <AppRoute exact path='/process-flow' name='ProcessFlowAdd' layout={TheLayoutAdmin} component={ProcessFlowAdd} />
          <AppRoute exact path='/process-flow-list' name='ProcessFlowList' layout={TheLayoutAdmin} component={ProcessFlowList} />
          <AppRoute exact path='/process-flow-update' name='ProcessFlowUpdate' layout={TheLayoutAdmin} component={ProcessFlowUpdate} />
          <AppRoute exact path='/process-flow-details' name='ProcessFlowDetails' layout={TheLayoutAdmin} component={ProcessFlowDetails} />

          <AppRoute exact path='/sup-balance-payment' name='ProcessFlowDetails' layout={TheLayoutAdmin} component={SupplierBalancePaymentList} />
          <AppRoute exact path='/cust-balance-payment' name='ProcessFlowDetails' layout={TheLayoutAdmin} component={CustomerBalancePaymentList} />
          <AppRoute exact path='/sup-enquery-followup' name='ProcessFlowDetails' layout={TheLayoutAdmin} component={SupplierEnquiryFollowupList} />
          <AppRoute exact path='/cust-enquery-followup' name='ProcessFlowDetails' layout={TheLayoutAdmin} component={CustomerEnquiryFollowupList} />

          <AppRoute exact path='/finished-goods' name='FinishedGoodsStore' layout={TheLayoutAdmin} component={FinishedGoodsStore} />
          <AppRoute exact path='/rejected-goods' name='RejectedGoodsStore' layout={TheLayoutAdmin} component={RejectedGoodsStore} />
          <AppRoute exact path='/finished-goods-store-history' name='FinishedGoodsStoreHistory' layout={TheLayoutAdmin} component={FinishedGoodsStoreHistory} />
          <AppRoute exact path='/rejected-goods-store-history' name='RejectedGoodsStoreHistory' layout={TheLayoutAdmin} component={RejectedGoodsStoreHistory} />

          <AppRoute exact path='/new-delivery-challan' name='NewDeliveryChallan' layout={TheLayoutAdmin} component={NewDeliveryChallan} />
          <AppRoute exact path='/delivery-challan-details' name='DeliveryChallanDetails' layout={TheLayoutAdmin} component={DeliveryChallanDetails} />
          <AppRoute exact path='/delivery-challan-created' name='DeliveryChallanCreatedList' layout={TheLayoutAdmin} component={DeliveryChallanCreatedList} />
          <AppRoute exact path='/delivery-challan-update' name='DeliveryChallanUpdate' layout={TheLayoutAdmin} component={DeliveryChallanUpdate} />
          <AppRoute exact path='/delivery-challan-list' name='DeliveryChallanMasterList' layout={TheLayoutAdmin} component={DeliveryChallanMasterList} />

          <AppRoute exact path='/cust-packing' name='CustPackingAdd' layout={TheLayoutAdmin} component={CustPackingAdd} />
          <AppRoute exact path='/cust-packing-list' name='CustPackingList' layout={TheLayoutAdmin} component={CustPackingList} />
          <AppRoute exact path='/cust-packing-update' name='CustPackingUpdate' layout={TheLayoutAdmin} component={CustPackingUpdate} />
          <AppRoute exact path='/cust-packing-details' name='CustPackingDetails' layout={TheLayoutAdmin} component={CustPackingDetails} />
          <AppRoute exact path='/cust-packing-created' name='CustPackingCreatedList' layout={TheLayoutAdmin} component={CustPackingCreatedList} />

          <AppRoute exact path='/debit-note' name='DebitNoteAdd' layout={TheLayoutAdmin} component={DebitNoteAdd} />
          <AppRoute exact path='/debit-note-list' name='DebitNoteList' layout={TheLayoutAdmin} component={DebitNoteList} />
          <AppRoute exact path='/debit-note-details' name='DebitNoteDetails' layout={TheLayoutAdmin} component={DebitNoteDetails} />
          <AppRoute exact path='/debit-note-edit' name='DebitNoteUpdate' layout={TheLayoutAdmin} component={DebitNoteUpdate} />

          <AppRoute exact path='/credit-note' name='CreditNoteAdd' layout={TheLayoutAdmin} component={CreditNoteAdd} />
          <AppRoute exact path='/credit-note-list' name='CreditNoteList' layout={TheLayoutAdmin} component={CreditNoteList} />
          <AppRoute exact path='/credit-note-details' name='CreditNoteDetails' layout={TheLayoutAdmin} component={CreditNoteDetails} />
          <AppRoute exact path='/credit-note-update' name='CreditNoteUpdate' layout={TheLayoutAdmin} component={CreditNoteUpdate} />

          <AppRoute exact path='/add-new-open-bal' name='OpenBalAdd' layout={TheLayoutAdmin} component={OpenBalAdd} />
          <AppRoute exact path='/open-close-bal' name='OpenCloseBalList' layout={TheLayoutAdmin} component={OpenCloseBalList} />
          <AppRoute exact path='/ledger-statement' name='LedgerStatement' layout={TheLayoutAdmin} component={LedgerStatement} />

          <AppRoute exact path='/user-role-name-add' name='UserRoleNameAdd' layout={TheLayoutAdmin} component={UserRoleNameAdd} />

          <AppRoute exact path='/user-role-add' name='UserRoleAdd' layout={TheLayoutAdmin} component={UserRoleAdd} />
          <AppRoute exact path='/user-role-list' name='UserRoleAdd' layout={TheLayoutAdmin} component={UserRoleList} />
          <AppRoute exact path='/user-role-details' name='UserRoleDetails' layout={TheLayoutAdmin} component={UserRoleDetails} />

          <AppRoute exact path='/import-cust-inv' name='CustInvImportExcel' layout={TheLayoutAdmin} component={CustInvImportExcel} />
          <AppRoute exact path='/import-sup-inv' name='SupPOInvImportExcel' layout={TheLayoutAdmin} component={SupPOInvImportExcel} />
          <AppRoute exact path='/import-cust-po' name='CustPOImportExcel' layout={TheLayoutAdmin} component={CustPOImportExcel} />
          <AppRoute exact path='/import-sup-po' name='SupPOImportExcel' layout={TheLayoutAdmin} component={SupPoImportExcel} />
          {/* Adronan */}
          <Route exact path="/adminlogin" name="Login Page" render={props => <AdminLogin {...props} />} />
          <Route exact path="/" name="Login Page" render={props => <AdminLogin {...props} />} />
          <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />

          <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />

          <Route exact path="/salary-slip-details" name="Salary Slip Details" render={props => <SalarySlipDetails {...props} />} />

          // <Route exact path="/salary-slip-details" name='Salary Slip Details' render={props => <SalarySlipDetails{...props} />} />

        </Switch>
        {/* )} */}
      </React.Suspense>
      <ToastContainer
        draggable={false}
        transition={Zoom}//zoom out
        autoClose={2000}//closing after 8sec
        position="top-center"
      />
      {/* </BrowserRouter> */}
    </HashRouter>
  );
}


export default App;
